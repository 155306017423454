import type { Auth, User, UserCredential } from "firebase/auth"
import type { Functions } from "firebase/functions"
import { getApp, initializeApp } from "firebase/app"
import { getAnalytics, initializeAnalytics, setUserId } from "firebase/analytics"
import { connectFirestoreEmulator, getFirestore, onSnapshot, doc, getDoc, type Unsubscribe } from "firebase/firestore"
import { connectAuthEmulator, getAuth, signInWithCustomToken } from "firebase/auth"
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions"
import { connectStorageEmulator, getStorage } from "firebase/storage"

/*
import productionConfig from "@/config/firebase.init.production.json"
import stagingConfig from "@/config/firebase.init.staging.json"
import staging2Config from "@/config/firebase.init.staging2.json"
*/

let systemSettings: SystemSettings = {}
let readSystemSettings: boolean = false

export async function init() {
  // 予約済み URL を使って SDK を初期化
  const response = await fetch("/__/firebase/init.json")
  if (response.ok) {
    const json = await response.json()
    const app = initializeApp(json)
    initializeAnalytics(app, {
      config: {
        cookie_flags: "SameSite=None;Secure",
      },
    })
  } else {
    throw new Error("firebase の初期化に失敗しました。")
  }

  /*
  // ロードバランサ等を利用していて予約済み URL が利用できない場合
  if (import.meta.env.MODE == "production" || import.meta.env.VITE_FIREBASE_CONFIG == "production") {
    initializeApp(productionConfig)
  } else if (import.meta.env.MODE == "staging" || import.meta.env.VITE_FIREBASE_CONFIG == "staging") {
    initializeApp(stagingConfig)
  } else if (import.meta.env.MODE == "staging2" || import.meta.env.VITE_FIREBASE_CONFIG == "staging2") {
    initializeApp(staging2Config)
  }
  */

  if (import.meta.env.VITE_USE_LOCAL) {
    console.warn("Firebase: use LocalEmulator")
    // ローカル環境でエミュレータを使う
    try {
      connectAuthEmulator(getAuth(), `http://${window.location.hostname}:9099`)
      connectFirestoreEmulator(getFirestore(), window.location.hostname, 8080)
      connectFunctionsEmulator(getFunctionsInTokyoRegion(), window.location.hostname, 5001)
      connectStorageEmulator(getStorage(), window.location.hostname, 9199)
    } catch {
      // HMR でエラーが出ることがあるので無視
    }
  }
}

/**
 * Analytics にユーザー情報を設定します。
 *
 * @param user ユーザー情報
 */
export function setAnalyticsUser(user: User) {
  setUserId(getAnalytics(), user.uid)
}

/**
 * Cloud Functions の東京リージョンのインスタンスを取得します。
 *
 * @returns 東京リージョンのインスタンス。
 */
export function getFunctionsInTokyoRegion(): Functions {
  return getFunctions(getApp(), "asia-northeast1")
}

export type ResponseBase = {
  result: string
  message?: string
}

/**
 * 認証 API のレスポンス。
 */
type SignInResponse = {
  token?: string
} & ResponseBase

/**
 * LoginID 認証 API のレスポンス。
 */
export type VerifyLoginCodeResponse = {
  sessionId?: string
} & ResponseBase

/**
 * セッション作成 API のレスポンス。
 */
type CreateLoginSessionResponse = {
  identifier?: string
} & ResponseBase

/**
 * コード認証でログインします。
 *
 * @param auth Firebase Authentication
 * @param code コード
 * @returns 認証情報を返します。
 */
export async function signInWithCode(auth: Auth, code: string): Promise<UserCredential> {
  const functions = getFunctionsInTokyoRegion()
  const method = httpsCallable<{ code: string }, SignInResponse>(functions, "verifyCode")

  const res = await method({ code })
  if (res.data.result !== "success") {
    throw new Error(res.data.message)
  }
  return signInWithCustomToken(auth, res.data.token)
}

/**
 * 認証コードが正しいかどうか確認します。
 *
 * @param loginCode 認証コード
 * @returns 結果を返します。
 */
export async function verifyLoginCode(loginCode: string): Promise<VerifyLoginCodeResponse> {
  const functions = getFunctionsInTokyoRegion()
  const method = httpsCallable<{ loginCode: string }, VerifyLoginCodeResponse>(functions, "verifyLoginCode")

  const res = await method({ loginCode })
  if (res.data.result === "success") {
    localStorage.setItem("loginCodeSession", res.data.sessionId)
  }
  return res.data
}

/**
 * 認証コードの確認で生成されたセッションの有効性を確認します。
 *
 * @returns 結果を返します。
 */
export async function isValidLoginCodeSession(): Promise<VerifyLoginCodeResponse> {
  const sessionId = localStorage.getItem("loginCodeSession")
  if (!sessionId) {
    return { result: "error", message: "セッションが不正です。" }
  }

  const functions = getFunctionsInTokyoRegion()
  const method = httpsCallable<{ sessionId: string }, VerifyLoginCodeResponse>(functions, "isValidSession")

  const res = await method({ sessionId })
  if (res.data.result !== "success") {
    localStorage.removeItem("loginCodeSession")
  }
  return res.data
}

/**
 * 多重ログイン監視（ブラウザ単位）。
 *
 * @param onExpired 他端末からログインされたときに呼び出されます。
 * @returns 監視処理を停止する関数をの Promise を返します。
 */
export async function watchLoginIdentifier(onExpired: () => void): Promise<Unsubscribe> {
  let identifier = sessionStorage.getItem("identifier")
  if (!identifier) {
    const functions = getFunctionsInTokyoRegion()
    const method = httpsCallable<{}, CreateLoginSessionResponse>(functions, "createLoginIdentifier")

    const res = await method()
    if (res.data.result !== "success") {
      throw new Error(res.data.message)
    }
    identifier = res.data.identifier
    sessionStorage.setItem("identifier", identifier)
  }

  // 他端末からのログインを監視
  const unsub = onSnapshot(doc(getFirestore(), "sessions", getAuth().currentUser.uid), (doc) => {
    if (!doc.exists() || doc.data().identifier != identifier) {
      sessionStorage.removeItem("identifier")
      unsub()
      onExpired()
    }
  })
  return unsub
}

// system settings 読み込み
async function getSystemSettings(): Promise<void> {
  if (readSystemSettings) {
    return
  }

  const systemRef = doc(getFirestore(), "system", "settings")
  const docSnap = await getDoc(systemRef)
  if (docSnap.exists()) {
    systemSettings = docSnap.data()
    readSystemSettings = true
  }
}

export async function getMaintStatus(): Promise<string | null> {
  await getSystemSettings()
  return systemSettings.maintStatus ?? null
}

export async function getLogin(): Promise<boolean> {
  await getSystemSettings()
  return systemSettings.login ?? false
}

export const LOGIN_CONTROL = {
  ASC: 0,
  DESC: 1,
}

export async function getLoginControl(): Promise<number> {
  await getSystemSettings()
  return systemSettings.loginControl ?? LOGIN_CONTROL.DESC
}

export async function getLoginExpiredSec(): Promise<number> {
  await getSystemSettings()
  return systemSettings.loginExpiredSec ?? 0
}

export async function getLoginMaxCount(): Promise<number> {
  return systemSettings.loginMaxCount ?? 1
}
