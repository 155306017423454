import { customAlphabet } from "nanoid"

// 文字種を定義
export const CharacterClass = {
  LowerCase: "abcdefghijkmnopqrstuvwxyz",
  UpperCase: "ABCDEFGHIJKLMNPQRSTUVWXYZ",
  Digit: "1234567890",
  Symbol: "#$?!",
}

// ユーザーIDを生成
export const generateUserCode = customAlphabet(
  "".concat(CharacterClass.LowerCase, CharacterClass.UpperCase, CharacterClass.Digit),
  10
)

export function uuid(): string {
  if (import.meta.env.DEV || !crypto.randomUUID) {
    let uuid = ""
    let random: number
    for (let i = 0; i < 32; i++) {
      random = (Math.random() * 16) | 0

      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-"
      }
      uuid += (i == 12 ? 4 : i == 16 ? (random & 3) | 8 : random).toString(16)
    }
    return uuid
  }
  return crypto.randomUUID()
}
