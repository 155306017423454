<script lang="ts">
  import { createEventDispatcher } from "svelte"

  const dispatch = createEventDispatcher<{
    click: MouseEvent
  }>()

  export let buttonColor: string = "#69C16F"
  export let disabled: boolean = false

  function onClickButton(e: MouseEvent) {
    dispatch("click", e)
  }
</script>

<button on:click={onClickButton} style:--btn-color={buttonColor} {disabled}>
  <slot />
</button>

<style>
  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.5em 1.5em;
    color: white;
    border: none;
    border-radius: 9999px;
    background-color: var(--btn-color);
    cursor: pointer;
  }
  button:hover {
    opacity: 80%;
  }
  button:disabled {
    background-color: gray;
    cursor: default;
  }
  button:hover:disabled {
    opacity: unset;
  }
</style>
