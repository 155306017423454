import type { Unsubscriber } from "svelte/store"
import { readonly } from "svelte/store"
import { getAuth, type User } from "firebase/auth"

import type { AsyncSubscriber } from "@/utils/async-writable"
import { loggedIn as loggedInStore } from "./container.svelte"

export { default as LoginView } from "./view.svelte"
export { default as SignInWithCode } from "./code.svelte"
export { default as SignInAnonymously } from "./anonymously.svelte"
export { default as UserAuthContainer } from "./container.svelte"

export const loggedIn = readonly(loggedInStore)

export enum LoginMethod {
  GOOGLE = "google",
  EMAIL = "email",
}

export function onLoggedIn(fn: AsyncSubscriber<User>): Unsubscriber {
  let isFirst = true
  const unsub = loggedInStore.subscribe(async (val) => {
    if (!isFirst && val) {
      const res = fn(getAuth().currentUser)
      if (res instanceof Promise) {
        await res
      }
    }
  })
  isFirst = false
  return unsub
}

export function onLoggedOut(fn: AsyncSubscriber<void>): Unsubscriber {
  let isFirst = true
  const unsub = loggedInStore.subscribe(async (val) => {
    if (!isFirst && !val) {
      const res = fn()
      if (res instanceof Promise) {
        await res
      }
    }
  })
  isFirst = false
  return unsub
}
