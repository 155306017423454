<script lang="ts">
  import type { StorageReference } from "firebase/storage"
  import { getAuth } from "firebase/auth"
  import { createEventDispatcher, onDestroy } from "svelte"
  import { type Unsubscribe, getFirestore, doc, onSnapshot } from "firebase/firestore"

  import { ContentType, type FileSharingData } from "../types"
  import Header from "./header.svelte"
  import Sidebar from "./sidebar.svelte"
  import ListView from "./list.svelte"
  import UploadProgress from "./upload-progress/index.svelte"

  export let selectedContetType: ContentType = ContentType.PDF
  export let fileSharingKey: string = ""
  export let editable: boolean = false
  const uid: string = getAuth().currentUser.uid
  const autoReloadDuration = 15000
  let intervID: NodeJS.Timeout = null
  let unsub: Unsubscribe
  let promise = init()
  let invalidateView: () => Promise<StorageReference[]>
  let deleteSelectedFiles: () => Promise<void>
  let hasSelectItem: boolean
  let fileSharings: FileSharingData[]
  let fileSharingData: FileSharingData

  function setAutoReload() {
    invalidateView()
    console.log("Auto reload Done...")
  }

  async function init(): Promise<void> {
    const settings = doc(getFirestore(), "app", "settings")
    unsub = onSnapshot(settings, (snapshot) => {
      if (snapshot.exists() && Array.isArray(snapshot.data().fileSharings)) {
        fileSharings = snapshot.data().fileSharings
        fileSharingData = fileSharings.find((data) => data.key == fileSharingKey) ?? { key: fileSharingKey }
      } else {
        fileSharings = []
        fileSharingData = { key: fileSharingKey }
      }
    })
    if (!intervID) {
      intervID = setInterval(setAutoReload, autoReloadDuration)
    }
  }

  const dispatch = createEventDispatcher<{
    startpresentation: { item: StorageReference; fileSharings: FileSharingData[] }
  }>()

  function onClickReload() {
    clearInterval(intervID)
    intervID = null
    invalidateView()
  }

  function onDeleteFiles() {
    deleteSelectedFiles?.()
  }

  function onScreenShare() {
    // TODO
  }

  function onOpenFile(e: CustomEvent<StorageReference>) {
    dispatch("startpresentation", {
      item: e.detail,
      fileSharings: fileSharings,
    })
  }

  onDestroy(() => {
    unsub()
    clearInterval(intervID)
    intervID = null
  })
</script>

<div class="materials">
  <div class="header">
    <Header
      contentType={selectedContetType}
      {editable}
      disabledDeleteButton={!hasSelectItem}
      on:reload={onClickReload}
      on:delete={onDeleteFiles}
      on:screenshare={onScreenShare}
    />
  </div>
  <div class="sidebar">
    <Sidebar bind:selectedContetType />
  </div>
  <div class="listview">
    <ListView
      contentType={selectedContetType}
      {editable}
      bind:invalidate={invalidateView}
      bind:deleteSelectedFiles
      bind:hasSelectItem
      on:open={onOpenFile}
      {fileSharingData}
    />
  </div>
  <div class="uploadprogress">
    <UploadProgress />
  </div>
</div>

<style>
  .materials {
    position: relative;
    display: grid;
    gap: 0.5em;
    grid-template-areas:
      "sidebar header"
      "sidebar content";
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-template-rows: auto 1fr;
    background: var(--presentation-background);
    border-radius: 5px;
    width: 100%;
    height: 100%;
  }
  .header {
    grid-area: header;
    grid-column: col-start / span 12;
  }
  .sidebar {
    grid-area: sidebar;
    grid-column: col-start / span 3;
    overflow: hidden;
  }
  .listview {
    grid-area: content;
    grid-column: col-start 4 / span 9;
    overflow: hidden;
    margin-right: 0.5em;
    margin-bottom: 1em;
    position: relative;
  }
  .uploadprogress {
    position: absolute;
    bottom: 1em;
    right: 1em;
  }
</style>
