<script lang="ts">
  export let size = "1rem"
  export let progress: number = 0
</script>

<div class="progress" style:--size={size} style:--progress={progress} />

<style>
  .progress {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--size);
    height: 1em;
    width: 1em;
    border-radius: 50%;
    background: conic-gradient(#6c7ef3 calc(360deg * var(--progress)), #acacac 0deg);
  }
  .progress::before {
    content: "";
    height: calc(1em - 6px);
    width: calc(1em - 6px);
    border-radius: 50%;
    background-color: #fff;
  }
</style>
