<script lang="ts">
  import type { StorageTask } from "../../storage"
  import { TaskStatus } from "../../storage"
  import { ContentType } from "../../types"
  import CircleProgress from "./circle-progress.svelte"

  export let task: StorageTask
  $: taskStatus = task.status
  $: taskProgress = task.progress

  function onClickCancel() {
    task.cancel()
  }
</script>

<div class="item">
  <div class="icon">
    {#if task.contentType == ContentType.PDF}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-filetype-pdf"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"
        />
      </svg>
    {/if}
  </div>
  <div class="name">
    {task.name}
  </div>
  <div class="process">
    {#if $taskStatus == TaskStatus.Running || $taskStatus == TaskStatus.Paused}
      <CircleProgress size="1.75rem" progress={$taskProgress} />
    {:else if $taskStatus == TaskStatus.Waiting}
      <button class="cancel" on:click={onClickCancel}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-x-circle"
          viewBox="0 0 16 16"
        >
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </button>
    {:else}
      <div class:task={true} class={$taskStatus} />
    {/if}
  </div>
</div>

<style>
  .item {
    display: flex;
    align-items: center;
    padding: 1em;
  }
  .icon {
    margin: 0 1em;
  }
  .icon > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  .name {
    min-width: 20em;
    margin-right: auto;
  }
  .process {
    color: #acacac;
  }
  .task.canceled::before {
    content: "中断";
    line-height: 1.75rem;
  }
  .task.completed::before {
    content: "完了";
    line-height: 1.75rem;
  }
  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: inherit;
    border: none;
    background: transparent;
    text-decoration: underline;
    cursor: pointer;
  }
  button > svg {
    width: 1.75rem;
    height: 1.75rem;
  }
</style>
