<script lang="ts">
  import type { StorageReference } from "firebase/storage"
  import { getDownloadURL, getStorage, ref, listAll } from "firebase/storage"
  import { createEventDispatcher, onDestroy } from "svelte"

  import { ContentType, type FileSharingData } from "../types"
  import Loading from "@/components/loading-atom.svelte"

  export let thisElement: HTMLDivElement = null
  export let contentType: ContentType
  export let item: StorageReference
  export let editable: boolean = false
  export let selected: boolean = false
  export let fileSharingData: FileSharingData
  export const reloadDuration = 15000

  const dispatch = createEventDispatcher<{
    change: { target: StorageReference }
    open: { item: StorageReference; status: string }
  }>()

  let promise = init()
  let converting = false
  let tid: NodeJS.Timeout = null
  let status: string

  onDestroy(() => {
    if (tid) {
      clearTimeout(tid)
    }
  })

  function isFileSame(fullPath: string): boolean {
    if (fileSharingData && fileSharingData.owner && fileSharingData.type && fileSharingData.name) {
      return fullPath == `presentation/${fileSharingData.owner}/output/${fileSharingData.type}/${fileSharingData.name}`
    } else {
      return false
    }
  }

  $: fileSharingData

  async function init(): Promise<string> {
    try {
      const storage = getStorage()
      const listRef = ref(storage, item.fullPath)
      const fileList = await listAll(listRef)
      for (let i = 0; i < fileList.items.length; i++) {
        if (fileList.items[i].name == "error.txt") {
          converting = false
          status = "error"
          break
        } else if (fileList.items[i].name == "warning.txt") {
          status = "warning"
        }
      }

      if (status == "error") {
        return
      }

      const thumbnailRef = ref(storage, `${item.fullPath}/thumbnail.jpg`)
      return await getDownloadURL(thumbnailRef)
    } catch (e) {
      converting = true
      tid = setTimeout(() => {
        promise = init()
        converting = false
      }, reloadDuration)
      throw e
    }
  }

  function onClick() {
    selected = !selected
    dispatch("change", { target: item })
  }

  function onDoubleClick() {
    dispatch("open", { item: item, status: status })
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  bind:this={thisElement}
  class="item"
  class:selected
  class:converting
  on:click|stopPropagation={onClick}
  on:dblclick|stopPropagation={onDoubleClick}
  on:pointerdown|stopPropagation={() => {}}
  role="button"
  tabindex="0"
>
  {#if contentType == ContentType.BGM}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-file-earmark-music"
      viewBox="0 0 16 16"
    >
      <path
        d="M11 6.64a1 1 0 0 0-1.243-.97l-1 .25A1 1 0 0 0 8 6.89v4.306A2.572 2.572 0 0 0 7 11c-.5 0-.974.134-1.338.377-.36.24-.662.628-.662 1.123s.301.883.662 1.123c.364.243.839.377 1.338.377.5 0 .974-.134 1.338-.377.36-.24.662-.628.662-1.123V8.89l2-.5V6.64z"
      />
      <path
        d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"
      />
    </svg>
  {:else}
    {#await promise then imgUrl}
      {#if status == "error"}
        <div class="thumnail">
          <div class="converr" />
          <div class="cross" />
        </div>
      {:else if status == "warning"}
        <img class="thumnail" src={imgUrl} alt={item.name} decoding="async" />
        {#if isFileSame(item.fullPath) && !editable}
          <p class="output">出力中</p>
        {/if}
        <p class="warn">表示崩れ要確認</p>
      {:else}
        <img class="thumnail" src={imgUrl} alt={item.name} decoding="async" />
        {#if isFileSame(item.fullPath) && !editable}
          <p class="output">出力中</p>
        {/if}
      {/if}
    {:catch}
      <div class="loading">
        <Loading />
      </div>
    {/await}
    <p class:converting>{item.name}</p>
  {/if}
</div>

<style>
  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.1em;
    border: 1px solid transparent;
    color: white;
    position: relative;
  }
  .item:hover {
    background: #ffffff30;
    cursor: pointer;
  }
  .item.selected {
    background: #2e73fc19;
    border: 1px solid #629bffcc;
  }
  .item.converting {
    cursor: initial;
    background: transparent;
    pointer-events: none;
  }
  .thumnail,
  .loading {
    padding: 1em;
    width: var(--materials-item-width);
    max-height: 100%;
    aspect-ratio: 16 / 9;
    object-fit: contain;
  }
  .thumnail {
    position: relative;
  }
  .output {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    color: white;
    background: blue;
    font-size: 15px;
    line-height: 1;
    padding: 5px 10px;
  }
  .warn {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    color: white;
    background: red;
    font-size: 15px;
    line-height: 1;
    padding: 5px 10px;
  }
  .converr {
    background: gray;
    position: absolute;
    width: calc(100% - 2em);
    height: calc(100% - 2em);
    z-index: 1;
  }
  .cross {
    position: absolute;
    width: 10px;
    height: 50px;
    margin-top: 24px;
    margin-left: 98px;
    background: black;
    transform: rotate(-45deg);
    z-index: 2;
  }
  .cross::after {
    content: "";
    position: absolute;
    top: 20px;
    left: -20px;
    width: 50px;
    height: 10px;
    background: black;
  }
  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    max-width: 100%;
  }
  p.converting {
    opacity: 0.5;
  }
  p.converting::before {
    content: "変換中: ";
  }
</style>
