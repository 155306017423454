<script lang="ts">
  import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth"

  import googleLogo from "@/assets/img/google.svg"

  async function onSignInWithGoogle() {
    try {
      const provider = new GoogleAuthProvider()
      await signInWithPopup(getAuth(), provider)
    } catch (error) {
      console.warn(error.message)
    }
  }
</script>

<button type="button" on:click={onSignInWithGoogle}>
  <img src={googleLogo} class="logo svelte" alt="Svelte Logo" />
  <span>Sign in with Google</span>
</button>

<style>
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

  button {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 8px;
    margin: 1em 0;
    border: none;
    border-radius: 0;
    box-shadow:
      2px 2px #00000024,
      0 3px 1px -2px #0003,
      0 1px 5px #0000001f;
    background-color: transparent;
    cursor: pointer;
  }
  button:hover {
    border: none;
  }
  button:active {
    box-shadow:
      0 4px 5px #00000024,
      0 1px 10px #0000001f,
      0 2px 4px -1px #0003;
  }
  button > img {
    height: 18px;
    width: 18px;
  }
  button > span {
    font-family: "Roboto", sans-serif;
    color: rgba(0, 0, 0, 54%);
    font-size: 14px;
    font-weight: 500;
    padding-left: 24px;
  }
</style>
