<script>
  export let size = "1.5em"
  export let color = "#6c7ef3"
</script>

<div role="status" aria-hidden="true" style:--size={size} style:--color={color} />

<style>
  div,
  div:after {
    border-radius: 50%;
    width: var(--size);
    height: var(--size);
  }
  div {
    position: relative;
    border-top: calc(var(--size) * 0.2) solid var(--color);
    border-right: calc(var(--size) * 0.2) solid var(--color);
    border-bottom: calc(var(--size) * 0.2) solid var(--color);
    border-left: calc(var(--size) * 0.2) solid lightgray;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
