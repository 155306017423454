<script lang="ts">
  import { LoginMethod } from "."
  import GoogleLoginButton from "./providers/google-login-button.svelte"
  import EmailLoginButton from "./providers/email-login-button.svelte"

  export let title = "Meta World"
  export let method: string = ""

  $: methods = parseMethod(method)

  function parseMethod(s: string): Set<LoginMethod> {
    const items = s
      .split(",")
      .filter((v) => Object.values(LoginMethod).some((p) => p == v))
      .map((x) => x as LoginMethod)
    if (items.length != 0) {
      return new Set(items)
    }

    // 未指定の場合は、全てのログインを使う
    return new Set(Object.values(LoginMethod))
  }
</script>

<div class="signin">
  <div class="signin__dialog">
    <h1>{title}</h1>
    {#if methods.has(LoginMethod.GOOGLE)}
      <GoogleLoginButton />
    {/if}
    {#if methods.has(LoginMethod.EMAIL)}
      {#if 1 < methods.size}
        <p class="bar">または</p>
      {/if}
      <EmailLoginButton />
    {/if}
  </div>
</div>

<style>
  .signin {
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }
  .signin__dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 1rem;
  }
  .bar {
    display: flex;
    margin: 1em 0;
    align-items: center;
    align-self: stretch;
  }
  .bar::before,
  .bar::after {
    content: "";
    flex-grow: 1;
    height: 1px;
    background: lightgray;
  }
  .bar::before {
    margin-right: 0.5em;
  }
  .bar::after {
    margin-left: 0.5em;
  }
</style>
