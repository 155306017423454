<script lang="ts" context="module">
  import { asyncWritable } from "@/utils/async-writable"
  export const loggedIn = asyncWritable<boolean>(false)
</script>

<script lang="ts">
  import type { ActionReturn } from "svelte/action"
  import type { Persistence, User } from "firebase/auth"
  import { browserSessionPersistence, getAuth, setPersistence } from "firebase/auth"

  import { signInDefault, type SignedInAttributes } from "./actions"
  import { setAnalyticsUser } from "@/utils/firebase"

  export let action: (node: HTMLElement, any?: any) => ActionReturn<any, SignedInAttributes> = signInDefault
  export let param: any = null
  export let persistence: Persistence = browserSessionPersistence

  // 認証状態を確認するまでは、ログイン画面もアプリ画面も表示しない
  let showLogin = false
  let showApp = false

  async function onSignIn(e: CustomEvent<User>) {
    // 認証状態の永続性の変更
    setPersistence(getAuth(), persistence)
    // Analytics にユーザー情報を紐付ける
    setAnalyticsUser(e.detail)

    await loggedIn.setAsync(true)
    showLogin = false
    showApp = true
  }

  async function onSignedOut() {
    await loggedIn.setAsync(false)
    showLogin = true
    showApp = false
  }
</script>

<div id="auth-container" on:signin={onSignIn} on:signedout={onSignedOut} use:action={param}>
  {#if showLogin}
    <slot name="login" />
  {/if}
  {#if showApp}
    <slot name="app" />
  {/if}
</div>
