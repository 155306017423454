<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import type { ContentType } from "../types"

  const dispatch = createEventDispatcher<{
    click: ContentType
  }>()

  export let active: boolean = false
  export let item: ContentType

  function onClickButton() {
    dispatch("click", item)
  }
</script>

<button class:active on:click={onClickButton}>
  <slot />
</button>

<style>
  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1em 2.5em;
    color: #969696;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  button:hover {
    color: #c0c0c0;
  }
  button.active {
    color: white;
    cursor: default;
  }
</style>
