<script lang="ts">
  import { getAuth, signInWithEmailAndPassword } from "firebase/auth"

  import LoadingSpinner from "../../loading-spinner.svelte"

  let email: string
  let passwd: string
  let errorMessage: string
  let inSubmitProgress = false

  async function onSignInWithEmail() {
    inSubmitProgress = true
    try {
      await signInWithEmailAndPassword(getAuth(), email, passwd)
    } catch (error) {
      errorMessage = error.message
    }
    inSubmitProgress = false
  }
</script>

<form on:submit|preventDefault={onSignInWithEmail}>
  <div class="form-label">
    <input type="email" id="inputEmail" placeholder=" " bind:value={email} />
    <label for="inputEmail">メールアドレス</label>
  </div>
  <div class="form-label">
    <input type="password" id="inputPasswd" placeholder=" " bind:value={passwd} />
    <label for="inputPasswd">パスワード</label>
  </div>
  <button type="submit" disabled={inSubmitProgress}>
    {#if inSubmitProgress}
      <LoadingSpinner />
    {/if}
    <span>パスワードでログイン</span>
  </button>
</form>
{#if errorMessage}
  <p style="color: red">{errorMessage}</p>
{/if}

<style>
  form {
    margin: 1em auto;
  }
  input {
    width: 300px;
    padding: 1.625em 0.75em 0.375em;
    border: 1px solid #ced4da;
    border-radius: 0.25em;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
  input:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25em rgba(13, 110, 253, 0.25);
  }
  .form-label {
    position: relative;
    margin-bottom: 1em;
  }
  label {
    position: absolute;
    top: 50%;
    left: 0.75em;
    opacity: 0.5;
    transform: translateY(-50%);
    transform-origin: left;
    transition: all 0.15s ease-in-out;
    user-select: none;
  }
  input:not(:placeholder-shown) + label {
    color: black;
    top: 1em;
    transform: translateY(-50%) scale(0.75);
  }
  input:focus + label {
    color: rgba(13, 110, 253);
    top: 1em;
    transform: translateY(-50%) scale(0.75);
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 100%;
    padding: 0 8px;
    margin: 1em 0;
    border: none;
    border-radius: 0;
    box-shadow:
      2px 2px #00000024,
      0 3px 1px -2px #0003,
      0 1px 5px #0000001f;
    background-color: transparent;
    cursor: pointer;
  }
  button:hover {
    border: none;
  }
  button:active {
    box-shadow:
      0 4px 5px #00000024,
      0 1px 10px #0000001f,
      0 2px 4px -1px #0003;
  }
  button > span {
    color: rgba(0, 0, 0, 60%);
    font-weight: bold;
  }
  button > span:nth-child(n + 2) {
    margin-left: 1em;
  }
</style>
