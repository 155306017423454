import type { StorageError, StorageReference, UploadTask, UploadTaskSnapshot } from "firebase/storage"
import { deleteObject, getStorage, listAll, ref, uploadBytesResumable } from "firebase/storage"
import { getAuth } from "firebase/auth"
import type { Readable, Writable } from "svelte/store"
import { get, writable } from "svelte/store"

import type { ContentType } from "./types"

export async function listPresentationMaterials(contentType: ContentType): Promise<StorageReference[]> {
  const uid = getAuth().currentUser.uid
  const listRef = ref(getStorage(), `presentation/${uid}/output/${contentType}`)
  return (await listAll(listRef)).prefixes
}

export async function deleteAllByContentType(contentType: ContentType): Promise<void> {
  const files = await listPresentationMaterials(contentType)
  for (const obj of files) {
    await deleteRecursive(obj)
  }
}

export async function deleteByName(fname: string, contentType: ContentType): Promise<void> {
  const uid = getAuth().currentUser.uid
  const objectRef = ref(getStorage(), `presentation/${uid}/output/${contentType}/${fname}`)
  await deleteRecursive(objectRef)
}

export async function deleteRecursive(ref: StorageReference) {
  try {
    const list = await listAll(ref)
    await Promise.all(list.items.map((x) => deleteObject(x)))
    await Promise.all(list.prefixes.map((x) => deleteRecursive(x)))
  } catch (e) {
    console.error(e)
  }
}

export const TaskStatus = {
  Waiting: "waiting",
  Running: "running",
  Paused: "paused",
  Completed: "completed",
  Canceled: "canceled",
} as const

export type TaskStatus = (typeof TaskStatus)[keyof typeof TaskStatus]

export class StorageTask {
  private _contentType: ContentType
  private _file: File
  private _uploadTask: UploadTask
  private _progress: Writable<number>
  private _status: Writable<TaskStatus>

  get contentType(): ContentType {
    return this._contentType
  }
  get name(): string {
    return this._file.name
  }
  get progress(): Readable<number> {
    return this._progress
  }
  get status(): Readable<TaskStatus> {
    return this._status
  }

  constructor(contentType: ContentType, file: File) {
    this._contentType = contentType
    this._file = file
    this._status = writable(TaskStatus.Waiting)
    this._progress = writable(0)
  }

  start() {
    const status = get(this._status)
    if (status == TaskStatus.Waiting) {
      // アップロードを開始
      const uid = getAuth().currentUser.uid
      const newFileRef = ref(getStorage(), `presentation/${uid}/${this._contentType}/${this.name}`)
      this._uploadTask = uploadBytesResumable(newFileRef, this._file)
      this._uploadTask.on("state_changed", this.onStateChanged, this.onUploadError, this.onUploadCompleted)
    } else if (status == TaskStatus.Paused) {
      // ポーズ中のタスクは再開
      this._uploadTask.resume()
    }
  }

  pause() {
    this._uploadTask?.pause()
  }

  cancel() {
    if (this._uploadTask) {
      this._uploadTask.cancel()
    } else if (get(this._status) == TaskStatus.Waiting) {
      this._status.set(TaskStatus.Canceled)
    }
  }

  onStateChanged = (snapshot: UploadTaskSnapshot) => {
    this._progress.set(snapshot.bytesTransferred / snapshot.totalBytes)
    switch (snapshot.state) {
      case "paused":
        console.log("Upload is paused", this.name)
        this._status.set(TaskStatus.Paused)
        break
      case "running":
        this._status.set(TaskStatus.Running)
        break
    }
  }

  onUploadCompleted = () => {
    this._progress.set(1)
    this._status.set(TaskStatus.Completed)
  }

  onUploadError = (_: StorageError) => {
    this._status.set(TaskStatus.Canceled)
  }
}

export class UploadManager {
  private _tasks: Writable<StorageTask[]>
  private _taskCount: Writable<number>

  get tasks(): Readable<StorageTask[]> {
    return this._tasks
  }
  get taskCount(): Readable<number> {
    return this._taskCount
  }

  constructor() {
    this._tasks = writable(<StorageTask[]>[])
    this._taskCount = writable(0)
  }

  upload(contentType: ContentType, file: File) {
    const t = new StorageTask(contentType, file)
    this._tasks.update((x) => [...x, t])
    t.status.subscribe((s) => {
      this.updateTaskCount()
      if (s == TaskStatus.Waiting || s == TaskStatus.Completed) {
        this.startNextTask()
      }
    })
  }

  private startNextTask() {
    for (const t of get(this._tasks)) {
      const status = get(t.status)
      if (status == TaskStatus.Running) {
        break
      } else if (status == TaskStatus.Waiting || status == TaskStatus.Paused) {
        t.start()
        break
      }
    }
  }

  private updateTaskCount() {
    const cnt = get(this._tasks).filter((x) => {
      const status = get(x.status)
      return status == TaskStatus.Running || status == TaskStatus.Waiting || status == TaskStatus.Paused
    }).length
    this._taskCount.set(cnt)
  }

  deleteCompletedOrCanceled() {
    this._tasks.update((x) =>
      x.filter((y) => {
        const status = get(y.status)
        return status != TaskStatus.Completed && status != TaskStatus.Canceled
      })
    )
  }
}

export const uploadManager = new UploadManager()
