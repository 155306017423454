export const ContentType = {
  IMAGE: "image",
  PDF: "pdf",
  VIDEO: "video",
  BGM: "bgm",
} as const

export type ContentType = (typeof ContentType)[keyof typeof ContentType]

export type FileSharingData = {
  key: string
  owner?: string
  type?: ContentType
  name?: string
  page?: number
}
