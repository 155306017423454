import type { ActionReturn } from "svelte/action"
import type { Unsubscribe, User } from "firebase/auth"
import { getAuth, onAuthStateChanged, onIdTokenChanged, signOut } from "firebase/auth"
import { doc, DocumentSnapshot, getFirestore, onSnapshot } from "firebase/firestore"
import { watchLoginIdentifier } from "@/utils/firebase"

export interface SignedInAttributes {
  "on:signin"?: (e: CustomEvent<User>) => void
  "on:signedout"?: (e: CustomEvent) => void
}

/**
 * 認証状態オブザーバーからユーザーデータを取得できたらサインインとする標準的なサインインアクション。
 *
 * @param node アクションを利用している HTMLElement。
 * @returns 要素がアンマウントされたときに呼び出す処理。
 */
export function signInDefault(node: HTMLElement): ActionReturn<void, SignedInAttributes> {
  const auth = getAuth()
  const unsubscribeAuthStateChanged = onAuthStateChanged(auth, async (user: User) => {
    if (user) {
      node.dispatchEvent(new CustomEvent("signin", { detail: user }))
    } else {
      node.dispatchEvent(new CustomEvent("signedout"))
    }
  })
  return {
    destroy() {
      unsubscribeAuthStateChanged()
    },
  }
}

/**
 * 匿名認証以外のユーザーデータを取得できたらサインインとするサインインアクション。
 *
 * @param node アクションを利用している HTMLElement。
 * @returns 要素がアンマウントされたときに呼び出す処理。
 */
export function signInWithoutAnonymously(node: HTMLElement): ActionReturn<void, SignedInAttributes> {
  const auth = getAuth()
  const unsubscribeAuthStateChanged = onAuthStateChanged(auth, async (user: User) => {
    if (user && !auth.currentUser.isAnonymous) {
      node.dispatchEvent(new CustomEvent("signin", { detail: user }))
    } else {
      node.dispatchEvent(new CustomEvent("signedout"))
    }
  })
  return {
    destroy() {
      unsubscribeAuthStateChanged()
    },
  }
}

/**
 * 匿名認証以外のユーザーデータを取得できたらサインインとするサインインアクションに、
 * 多重ログイン監視を行うようにしたサインインアクション。
 *
 * @param node アクションを利用している HTMLElement。
 * @returns 要素がアンマウントされたときに呼び出す処理。
 */
export function signInOnlyOneSession(node: HTMLElement): ActionReturn<void, SignedInAttributes> {
  const auth = getAuth()
  let unsub: Unsubscribe = null
  const unsubscribeAuthStateChanged = onAuthStateChanged(auth, async (user: User) => {
    unsub?.()
    unsub = null
    if (user && !auth.currentUser.isAnonymous) {
      // 多重ログインを監視
      unsub = await watchLoginIdentifier(() => {
        signOut(auth)
      })
      node.dispatchEvent(new CustomEvent("signin", { detail: user }))
    } else {
      node.dispatchEvent(new CustomEvent("signedout"))
    }
  })
  return {
    destroy() {
      unsubscribeAuthStateChanged()
    },
  }
}

/**
 * カスタムクレームを元にサインイン状態を判断するサインインアクション。
 *
 * @param node アクションを利用している HTMLElement。
 * @param hasClaims ユーザーデータを渡し必要なクレームを持つか判断するメソッドを渡します。
 * @returns 要素がアンマウントされたときに呼び出す処理。
 */
export function signInClaims(
  node: HTMLElement,
  hasClaims: (User) => Promise<boolean>
): ActionReturn<void, SignedInAttributes> {
  let auth = getAuth()
  let unsubscribe: Unsubscribe = null
  const unsubscribeAuthStateChanged = onAuthStateChanged(auth, async (user: User) => {
    unsubscribe?.()
    unsubscribe = null

    if (user) {
      const userRef = doc(getFirestore(), "users", user.uid)
      unsubscribe = onSnapshot(userRef, async (_: DocumentSnapshot) => {
        // ID トークンを強制的に更新し、カスタムクレームの変更を受け取る
        // 参照: https://firebase.google.com/docs/auth/admin/custom-claims?hl=ja#best_practices_for_custom_claims
        user.getIdToken(true)
      })
    } else {
      node.dispatchEvent(new CustomEvent("signedout"))
    }
  })

  const unsubscribeIdTokenChanged = onIdTokenChanged(auth, async (user: User) => {
    if (user) {
      if (await hasClaims(user)) {
        node.dispatchEvent(new CustomEvent("signin", { detail: user }))
      } else {
        node.dispatchEvent(new CustomEvent("signedout"))
      }
    }
  })

  return {
    destroy() {
      unsubscribeAuthStateChanged()
      unsubscribeIdTokenChanged()
      unsubscribe?.()
    },
  }
}
