<script lang="ts">
  import { createEventDispatcher } from "svelte"

  import { ContentType } from "../types"
  import { uploadManager } from "../storage"
  import HeaderButton from "../share/button.svelte"

  const dispatch = createEventDispatcher<{
    reload: void
    delete: MouseEvent
    screenshare: MouseEvent
  }>()

  export let editable: boolean = false
  export let disabledDeleteButton: boolean
  // コンテンツのタイプ
  export let contentType: ContentType

  const Accept = {
    [ContentType.IMAGE]: "image/*",
    [ContentType.PDF]: ".pdf",
    [ContentType.VIDEO]: "video/*",
    [ContentType.BGM]: "audio/*",
  } as const

  $: accept = Accept[contentType]
  let inputElement: HTMLInputElement

  function onClickDelete(e: CustomEvent<MouseEvent>) {
    dispatch("delete", e.detail)
  }

  function onClickScreenShare(e: CustomEvent<MouseEvent>) {
    dispatch("screenshare", e.detail)
  }

  function onClickUpload() {
    inputElement.click()
  }

  function onChange() {
    const [file] = inputElement.files
    if (file) {
      uploadManager.upload(contentType, file)
    }
    inputElement.value = null
  }

  function onClickReload() {
    dispatch("reload")
  }
</script>

<div class="header">
  <HeaderButton on:click={onClickReload} buttonColor="#A1CFF4">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-arrow-clockwise"
      viewBox="0 0 16 16"
    >
      <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
    </svg>
    <span>更新</span>
  </HeaderButton>

  {#if editable}
    <input type="file" {accept} bind:this={inputElement} on:change={onChange} />
    <HeaderButton on:click={onClickUpload} buttonColor="#69C16F">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-plus-circle"
        viewBox="0 0 16 16"
      >
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path
          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
        />
      </svg>
      <span>ファイルを追加する</span>
    </HeaderButton>

    <HeaderButton on:click={onClickDelete} buttonColor="#F64A4A" disabled={disabledDeleteButton}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-trash-fill"
        viewBox="0 0 16 16"
      >
        <path
          d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"
        />
      </svg>
      <span>削除する</span>
    </HeaderButton>
  {:else}
    <a href="/admin/presentation/" target="_blank">
      <HeaderButton buttonColor="#69C16F">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-plus-circle"
          viewBox="0 0 16 16"
        >
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path
            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
          />
        </svg>
        <span>ファイルをアップロードする</span>
      </HeaderButton>
    </a>
    <!--
    <HeaderButton on:click={onClickScreenShare} buttonColor="#5F6ECB">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-display"
        viewBox="0 0 16 16"
      >
        <path
          d="M0 4s0-2 2-2h12s2 0 2 2v6s0 2-2 2h-4c0 .667.083 1.167.25 1.5H11a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1h.75c.167-.333.25-.833.25-1.5H2s-2 0-2-2V4zm1.398-.855a.758.758 0 0 0-.254.302A1.46 1.46 0 0 0 1 4.01V10c0 .325.078.502.145.602.07.105.17.188.302.254a1.464 1.464 0 0 0 .538.143L2.01 11H14c.325 0 .502-.078.602-.145a.758.758 0 0 0 .254-.302 1.464 1.464 0 0 0 .143-.538L15 9.99V4c0-.325-.078-.502-.145-.602a.757.757 0 0 0-.302-.254A1.46 1.46 0 0 0 13.99 3H2c-.325 0-.502.078-.602.145z"
        />
      </svg>
      <span>画面を共有する</span>
    </HeaderButton>
    -->
  {/if}
</div>

<style>
  input {
    display: none;
  }
  a {
    text-decoration: none;
  }
  .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1em;
    margin: 1.5em;
  }
  svg {
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.5em;
  }
</style>
