import type { Invalidator, StartStopNotifier, Updater } from "svelte/store"
import { noop, safe_not_equal } from "svelte/internal"

const subscriber_queue = []

export type AsyncSubscriber<T> = (value: T) => void | Promise<void>

/**
 * 非同期サブスクライバーを同期的に処理する writable ストアを作成する。
 */
export function asyncWritable<T>(value: T, start: StartStopNotifier<T> = noop) {
  let stop: Invalidator<T>

  const subscribers = new Set<[AsyncSubscriber<T>, Invalidator<T>]>()
  const typed_subscriber_queue: Array<[[AsyncSubscriber<T>, Invalidator<T>], T]> = subscriber_queue

  async function setAsync(new_value: T): Promise<void> {
    if (safe_not_equal(value, new_value)) {
      value = new_value
      if (stop) {
        // store is ready
        const run_queue = !typed_subscriber_queue.length
        for (const subscriber of subscribers) {
          subscriber[1]()
          typed_subscriber_queue.push([subscriber, value])
        }
        if (run_queue) {
          for (let i = 0; i < typed_subscriber_queue.length; i++) {
            const res = typed_subscriber_queue[i][0][0](typed_subscriber_queue[i][1])
            if (res instanceof Promise) {
              await res
            }
          }
          typed_subscriber_queue.length = 0
        }
      }
    }
  }

  async function updateAsync(fn: Updater<T>): Promise<void> {
    await setAsync(fn(value))
  }

  function subscribe(run: AsyncSubscriber<T>, invalidate: Invalidator<T> = noop) {
    const subscriber: [AsyncSubscriber<T>, Invalidator<T>] = [run, invalidate]
    subscribers.add(subscriber)
    if (subscribers.size === 1) {
      stop = start(setAsync, updateAsync) || noop
    }
    run(value)
    return () => {
      subscribers.delete(subscriber)
      if (subscribers.size === 0 && stop) {
        stop()
        stop = null
      }
    }
  }
  return { setAsync, updateAsync, subscribe }
}
