<script lang="ts">
  import type { StorageReference } from "firebase/storage"
  import { createEventDispatcher } from "svelte"

  import Loading from "@/components/loading-google.svelte"
  import { ContentType, type FileSharingData } from "../types"
  import { deleteRecursive, listPresentationMaterials } from "../storage"
  import Item from "./item.svelte"

  const dispatch = createEventDispatcher<{
    open: StorageReference
  }>()

  let promise: Promise<StorageReference[]>

  // コンテンツのタイプ
  export let contentType: ContentType
  // 編集可能にするかどうか
  export let editable: boolean = false
  // ファイルの表示幅
  export let itemWidth: string = "15em"
  // 選択されたアイテムが一つでもあるかどうか
  export let hasSelectItem: boolean = false
  export let fileSharingData: FileSharingData

  let itemListElement: HTMLDivElement
  let selectionElement: HTMLDivElement

  // 選択範囲 Element の調整用変数
  let dragStartEvent: PointerEvent
  let selecting: boolean = false
  let selectionTop: number = 0
  let selectionLeft: number = 0
  let selectionWidth: number = 0
  let selectionHeight: number = 0

  let itemLength: number
  let refs: HTMLDivElement[] = []
  let isSelectedList: boolean[] = []
  let showDialogStatus: string
  let selectedItem: StorageReference

  // コンテンツタイプが変更されたら、ファイル一覧を更新する
  $: contentType && invalidate()
  // 選択リストが変更されたら、選択アイテムの有無を更新
  $: hasSelectItem = isSelectedList.some((i) => i)
  $: fileSharingData && invalidate()

  export async function invalidate(): Promise<StorageReference[]> {
    promise = listPresentationMaterials(contentType)
    promise.then((res) => {
      itemLength = res.length
    })
    refs = []
    isSelectedList = []
    return promise
  }

  export async function deleteSelectedFiles() {
    const items = await promise
    for (let i = 0; i < items.length; i++) {
      if (isSelectedList[i]) {
        await deleteRecursive(items[i])
      }
    }
    await invalidate()
  }

  async function onChangeSelect(e: CustomEvent<{ target: StorageReference }>) {
    if (editable) {
      return
    }

    const items = await promise
    for (let i = 0; i < itemLength; i++) {
      if (items[i] != e.detail.target) {
        isSelectedList[i] = false
      }
    }
  }

  function onOpen(e: CustomEvent<{ item: StorageReference; status: string }>) {
    if (e.detail.status == "warning") {
      showDialogStatus = "warning"
      selectedItem = e.detail.item
    }
    dispatch("open", e.detail.item)
  }

  function onPointerDown(e: PointerEvent) {
    if (editable) {
      dragStartEvent = e
      document.addEventListener("pointermove", onDragStart)
      document.addEventListener("pointerup", onDragEnd)
      document.addEventListener("pointercancel", onDragEnd)
    }
  }

  function onDragStart(e: PointerEvent) {
    e.stopPropagation()

    // 選択範囲 Element を表示し、選択状態をリセット
    if (!selecting) {
      selecting = true
      for (let i = 0; i < itemLength; i++) {
        isSelectedList[i] = false
      }
    }

    // 選択範囲 Element の位置を計算
    const rect = itemListElement.getBoundingClientRect()
    if (dragStartEvent.clientX < e.clientX) {
      selectionLeft = dragStartEvent.clientX - rect.left
      selectionWidth = Math.min(e.clientX, window.innerWidth) - dragStartEvent.clientX
    } else {
      selectionLeft = Math.max(e.clientX - rect.left, 0)
      selectionWidth = dragStartEvent.clientX - Math.max(e.clientX, rect.left)
    }

    if (dragStartEvent.clientY < e.clientY) {
      selectionTop = dragStartEvent.clientY - rect.top + itemListElement.scrollTop
      selectionHeight = Math.min(e.clientY, window.innerHeight) - dragStartEvent.clientY
    } else {
      selectionTop = Math.max(e.clientY - rect.top + itemListElement.scrollTop, itemListElement.scrollTop)
      selectionHeight = dragStartEvent.clientY - Math.max(e.clientY, rect.top)
    }

    // 選択範囲内のアイテムを算出
    setupSelection()
  }

  function onDragEnd(e: PointerEvent) {
    e.stopPropagation()

    // シングルタップは選択状態をリセット
    if (!selecting && hasSelectItem) {
      for (let i = 0; i < itemLength; i++) {
        isSelectedList[i] = false
      }
    }

    dragStartEvent = null
    selecting = false
    document.removeEventListener("pointermove", onDragStart)
    document.removeEventListener("pointerup", onDragEnd)
    document.removeEventListener("pointercancel", onDragEnd)
  }

  // スクロールできる表示領域内の最初の Item のインデックス
  let scrollTopIndex: number = 0
  // 表示領域内の Item をキャッシュしておくことで、
  // 選択アイテムの算出処理の負荷を下げる
  function onScroll() {
    const rect = itemListElement.getBoundingClientRect()
    for (let i = 0; i < itemLength; i++) {
      const r = refs[i].getBoundingClientRect()
      if (rect.top <= r.top + r.height) {
        scrollTopIndex = i
        break
      }
    }
  }

  // 選択範囲 Element と重なったアイテムの選択状態を設定する
  function setupSelection() {
    const rect = itemListElement.getBoundingClientRect()
    const selectionRect = selectionElement.getBoundingClientRect()
    for (let i = scrollTopIndex; i < itemLength; i++) {
      const itemRect = refs[i].getBoundingClientRect()
      if (rect.top + rect.height < itemRect.top) {
        break
      }
      isSelectedList[i] = !(
        selectionRect.top > itemRect.bottom ||
        selectionRect.right < itemRect.left ||
        selectionRect.bottom < itemRect.top ||
        selectionRect.left > itemRect.right
      )
    }
  }

  function handleClick(answer) {
    if (answer) {
      dispatch("open", selectedItem)
    }
    showDialogStatus = ""
  }
</script>

{#await promise}
  <div class="center">
    <Loading />
  </div>
{:then items}
  <div
    bind:this={itemListElement}
    class="scrollable"
    style:--materials-item-width={itemWidth}
    on:pointerdown|stopPropagation={onPointerDown}
    on:scroll={onScroll}
  >
    <div
      bind:this={selectionElement}
      class="selection"
      class:active={selecting}
      style:--top={`${selectionTop}px`}
      style:--height={`${selectionHeight}px`}
      style:--left={`${selectionLeft}px`}
      style:--width={`${selectionWidth}px`}
    />
    <ul>
      {#each items as item, idx (item.fullPath)}
        <li>
          <Item
            bind:thisElement={refs[idx]}
            {item}
            {contentType}
            {editable}
            {fileSharingData}
            bind:selected={isSelectedList[idx]}
            on:change={onChangeSelect}
            on:open={onOpen}
          />
        </li>
      {/each}
    </ul>
  </div>
  {#if showDialogStatus == "warning"}
    <div class="dialog">
      <p>変換した結果、一部表示崩れが発生している可能性がございます。</p>
      <p>一度ファイルの内容をご確認ください。</p>
      <div class="button-container">
        <button on:click={() => handleClick(true)}>OK</button>
      </div>
    </div>
  {/if}
{:catch error}
  <p style="color: red">{error.message}</p>
{/await}

<style>
  ul {
    list-style: none;
    margin: 0 0 1em;
    padding: 0;

    display: grid;
    grid-template-columns: repeat(auto-fill, var(--materials-item-width));
    justify-content: center;
    grid-gap: 2em;
    width: 100%;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .scrollable {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    user-select: none;
    touch-action: none;
    padding: 1em 0;
    height: 100%;
  }
  .scrollable::-webkit-scrollbar {
    width: 6px;
  }
  .scrollable::-webkit-scrollbar-track {
    border-radius: 1px;
    background: #575757;
  }
  .scrollable::-webkit-scrollbar-thumb {
    border-radius: 1px;
    background: white;
  }
  .selection {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: var(--width);
    height: var(--height);
    transform: translate(var(--left), var(--top));
    background: #2e73fc19;
    border: 1px solid #629bffcc;
    border-radius: 0.1em;
  }
  .selection.active {
    display: block;
  }
  .dialog {
    position: absolute;
    max-width: 33em;
    height: 10em;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 4px;
    top: calc(50% - 15em);
    left: calc(50% - 17em);
  }
  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
</style>
