<script lang="ts">
  import { get } from "svelte/store"
  import { fade } from "svelte/transition"

  import { TaskStatus, uploadManager } from "../../storage"
  import ThreeDots from "./three-dots.svelte"
  import Item from "./item.svelte"

  const taskStore = uploadManager.tasks
  const taskCount = uploadManager.taskCount
  let visibleThis: boolean
  let timeoutId: NodeJS.Timeout = null
  $: if ($taskCount > 0) {
    if (timeoutId) {
      clearTimeout(timeoutId)
      timeoutId = null
    }
    visibleThis = true
  } else {
    // `visibleThis` をすぐに更新してしまうと、ヘッダー部のアップロード中表示などが更新されないので、時間差で更新する
    timeoutId = setTimeout(() => {
      visibleThis = false
      timeoutId = null
    }, 1000)
  }

  function cleanupAction(_: HTMLElement) {
    return {
      destroy() {
        uploadManager.deleteCompletedOrCanceled()
      },
    }
  }

  function onClickCancel() {
    $taskStore.forEach((t) => {
      const status = get(t.status)
      if (status == TaskStatus.Running || status == TaskStatus.Waiting) {
        t.cancel()
      }
    })
  }
</script>

{#if visibleThis}
  <div class="upload-container" use:cleanupAction in:fade={{ duration: 50 }} out:fade={{ delay: 3000, duration: 750 }}>
    <div class="upload-header">
      {#if $taskCount > 0}
        <ThreeDots size="0.625rem" />
        <span>{$taskCount}個のファイルをアップロード中</span>
        <button on:click={onClickCancel}>キャンセル</button>
      {/if}
    </div>
    <ul>
      {#each $taskStore as task}
        <li>
          <Item {task} />
        </li>
      {/each}
    </ul>
  </div>
{/if}

<style>
  .upload-container {
    display: flex;
    flex-direction: column;
    font-size: 0.875em;
    border-radius: 1em;
    background: white;
    overflow: hidden;
  }
  .upload-header {
    display: flex;
    align-items: center;
    color: white;
    background: #6c7ef3;
    height: 2.5em;
    padding-left: 1em;
  }
  .upload-header > span {
    margin-left: 0.5em;
    margin-right: 2em;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li + li {
    border-top: 1px solid #707070;
  }
  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    padding: 0.5em 1em;
    color: white;
    border: none;
    background: transparent;
    text-decoration: underline;
    cursor: pointer;
  }
</style>
