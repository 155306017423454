<script lang="ts">
  export let size = "1rem"
</script>

<div class="loader" style:--size={size}>
  <span class="dot" />
  <span class="dot" />
  <span class="dot" />
</div>

<style>
  .loader {
    font-size: var(--size);
    display: flex;
    gap: 0.5em;
    margin: 0 0.5em;
  }
  .dot {
    position: relative;
    width: 1em;
    height: 1em;
  }
  .dot::before {
    position: absolute;
    content: "";
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background: white;
    animation: load 1.8s infinite ease-in-out;
    animation-fill-mode: both;
  }
  .dot:nth-child(2)::before {
    animation-delay: 0.16s;
  }
  .dot:nth-child(3)::before {
    animation-delay: 0.32s;
  }
  @keyframes load {
    0%,
    80%,
    100% {
      scale: 1;
    }
    40% {
      scale: 0;
    }
  }
</style>
